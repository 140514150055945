import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import agent from "../../../api/agent";
import AppUser from "../../../models/Users/appUser";
import { Grid, Header, Item, Segment, List, Dimmer, Loader, Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../stores/store";

interface AdministartorProfileParams {
  userId: string;
}

export default function AdministartorProfile() {
  let { userId } = useParams<AdministartorProfileParams>();
  const [user, setUser] = useState<AppUser>();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const { t } = useTranslation("common");
  const { userStore } = useStore();

  useEffect(() => {
    setInProgress(true);
    agent.Users.details(userId)
      .then((result) => {
        setUser(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setInProgress(false));
  }, []);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
    <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          {userStore.user?.id === +userId && <span>{t("UserManagement.MyProfile")}</span>}
      {userStore.user?.id !== +userId && (
        <span>{t("UserManagement.Administrators.AdminProfile")}</span>
      )}
          </a>
      </nav>
      {userStore.user?.id === +userId && <Header as="h2">{t("UserManagement.MyProfile")}</Header>}
      {userStore.user?.id !== +userId && (
        <Header as="h2">{t("UserManagement.Administrators.AdminProfile")}</Header>
      )}
      <div className="ui menu injaz-users-forms-btns">
        <div className="item">
          {userStore.isAdmin && (
            <Button basic color="blue" as={NavLink} to="/manageusers/admin">
              {t("Common.Back")}
            </Button>
          )}
        </div>
        <div className="item">
          <Button
            content={t("Common.Edit")}
            basic
            color="blue"
            as={NavLink}
            to={`/admin/${userId}/edit`}
          />
        </div>
      </div>
      <Segment className="injaz-forms-segment">
        <Grid columns={2} className="profile-info" divided stackable>
          <Grid.Row className="">
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.FirstName")}: </List.Header> {user?.firstName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.MiddleName")}: </List.Header>
                  {user?.middleName == null ? "--" : user?.middleName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.LastName")}: </List.Header>
                  {user?.lastName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.EmailAddress")}: </List.Header>
                  {user?.email == null ? "--" : user?.email}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.PhoneNumber")}: </List.Header>
                  {user?.phoneNumber == null ? "--" : user?.phoneNumber}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.Address")}: </List.Header>
                  {user?.address == null ? "--" : user?.address}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {/*<div className="profile-certificates">
        <Header as="h2">My Certifications</Header>
        <Segment>
          <Item>
            <Item.Image size="tiny" src="https://react.semantic-ui.com/images/wireframe/image.png" />
            <Item.Content>
              <Item.Header as="a">Header</Item.Header>
              <Item.Meta>Description</Item.Meta>
              <Item.Description>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet.
              </Item.Description>
              <Item.Extra>Additional Details</Item.Extra>
            </Item.Content>
          </Item>
        </Segment>
        <Segment>
          <Item>
            <Item.Image size="tiny" src="https://react.semantic-ui.com/images/wireframe/image.png" />
            <Item.Content>
              <Item.Header as="a">Header</Item.Header>
              <Item.Meta>Description</Item.Meta>
              <Item.Description>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet.
              </Item.Description>
              <Item.Extra>Additional Details</Item.Extra>
            </Item.Content>
          </Item>
        </Segment>
      </div>*/}
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
