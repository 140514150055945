import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button, Header, Segment, Grid, Dimmer, Loader, Form } from "semantic-ui-react";
import agent from "../../api/agent";
import { AcademicYearDto } from "../../models/academicYearDto";
import { DonorDto } from "../../models/donorDto";
import { ProgramDto } from "../../models/programDto";
import { ProjectDto } from "../../models/projectDto";
import * as Yup from "yup";
import CustomTextInput from "../../common/forms/CustomTextInput";
import CustomSelectInput from "../../common/forms/CustomSelectInput";
import { useStore } from "../../stores/store";
import CustomTextAreaInput from "../../common/forms/CustomTextAreaInput";
import CustomMultiSelectInput from "../../common/forms/CustomMultiSelectInput";
import { LookupDto } from "../../models/lookupDto";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomCheckboxInput from "../../common/forms/CustomCheckboxInput";

interface Params {
  programId: string | undefined;
}

export default function AddEditProgram() {
  const { programId } = useParams<Params>();
  const { t } = useTranslation("common");
  const history = useHistory();
  const [donors, setDonors] = useState<DonorDto[]>([]);
  const [academicYears, setAcademicYears] = useState<AcademicYearDto[]>([]);
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const [programs, setPrograms] = useState<LookupDto[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [semsters, setSemsters] = useState<{ text: string; value: number }[]>([]);
  const [thematicAreas, setThematicAreas] = useState<{ text: string; value: number }[]>([]);
  const [targetGroups, setTargetGroups] = useState<{ text: string; value: number; key: number }[]>([]);
  const [educationLevels, setEducationLevels] = useState<{ text: string; value: number; key: number }[]>(
    []
  );
  const [expereienceDomains, setExpereienceDomains] = useState<
    { text: string; value: number; key: number }[]
  >([]);
  const [majors, setMajors] = useState<{ text: string; value: number; key: number }[]>([]);
  const [institutions, setInstitutions] = useState<{ text: string; value: number }[]>([]);
  const { lookupsStore, commonStore } = useStore();
  const [sessionCount, setSessionCount] = useState<number>(1);
  const [sessions, setSessions] = useState<{ text: string; value: number }[]>([]);
  const [prog, setProg] = useState<ProgramDto>({
    id: 0,
    name: "",
    shortName: "",
    academicYearId: undefined,
    projectId: undefined,
    semester: undefined,
    donorId: undefined,
    programConcatenatedName: "",
    targetInstitutionTypes: undefined,
    brief: "",
    numberOfsessions: undefined,
    numberOfProgramHours: undefined,
    outcomes: null,
    targetGroupId: undefined,
    thematicAreaType: undefined,
    version: "",
    prerequisiteCourses: [],
    graduationCriteria_SurveyFilled: false,
    graduationCriteria_MinimumNumberOfAttendedSessions: undefined,
    graduationCriteria_AttendSpecificSessions: [],
    volunteerSelectionCriteria_MinimumYearsOfExperience: undefined,
    volunteerSelectionCriteria_MinimumLevelOfeducationId: undefined,
    volunteerSelectionCriteria_ExperienceDomains: [],
    volunteerSelectionCriteria_Majors: [],
    donorName: "",
    academicYearName: "",
    semsterName: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Common.Required")),
    shortName: Yup.string().required(t("Common.Required")),
    brief: Yup.string().required(t("Common.Required")),
    thematicAreaType: Yup.string().required(t("Common.Required")),
    donorId: Yup.string().required(t("Common.Required")),
    projectId: Yup.string().required(t("Common.Required")),
    academicYearId: Yup.string().required(t("Common.Required")),
    semester: Yup.string().required(t("Common.Required")),
    numberOfsessions: Yup.number().typeError(t("Common.InvalidNumber")).required(t("Common.Required")),
    numberOfProgramHours: Yup.number()
      .typeError(t("Common.InvalidNumber"))
      .required(t("Common.Required")),
    targetInstitutionTypes: Yup.array()
      .min(1, t("ProgramManagement.PleaseSelectInstitutionTypes"))
      .required(t("Common.Required")),
    targetGroupId: Yup.string().required(t("Common.Required")),
    version: Yup.string().required(t("Common.Required")),
    programConcatenatedName: Yup.string().required(t("Common.Required")),
  });

  const submitForm = (values: ProgramDto) => {
    setProg(values);
    setIsSaving(true);
    if (!programId || programId == null) addNewProgram(values);
    else updateProgram(values);
  };

  const addNewProgram = (values: ProgramDto) => {
    agent.Programs.add(values)
      .then((result) => {
        toast.success(t("ProgramManagement.ProgramAddedSuccessfully"));
        history.push(`/programdetails/${result}`);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateProgram = (values: ProgramDto) => {
    agent.Programs.updateProgram(values)
      .then((result) => {
        toast.success(t("ProgramManagement.ProgramUpdatedSuccessfully"));
        history.push(`/programdetails/${programId}`);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    agent.Programs.lookupList()
      .then((result) => {
        if (programId != null && +programId > 0) result = result.filter((p) => p.value !== +programId);
        setPrograms(result);
      })
      .catch((error) => handleError(error))
      .finally(() => {});

    agent.Donors.list()
      .then((result) => setDonors(result))
      .catch((error) => handleError(error))
      .finally(() => {});

    agent.AcademicYears.list()
      .then((result) => setAcademicYears(result))
      .catch((error) => handleError(error))
      .finally(() => {});

    agent.Projects.list()
      .then((result) => setProjects(result))
      .catch((error) => handleError(error))
      .finally(() => {});

    agent.Lookups.targetGroups()
      .then((result) => setTargetGroups(result))
      .catch((error) => handleError(error));

    agent.EducationLevels.list()
      .then((result) => setEducationLevels(result))
      .catch((error) => handleError(error))
      .finally(() => {});

    agent.ExperienceDomains.list()
      .then((result) => setExpereienceDomains(result))
      .catch((error) => handleError(error))
      .finally(() => {});

    agent.Lookups.majors()
      .then((result) => setMajors(result))
      .catch((error) => handleError(error));
  }, []);

  useEffect(() => {
    let translatedSemsters = lookupsStore.getSemsters(commonStore.lang);
    setSemsters(translatedSemsters);

    let translatedInstitutions = lookupsStore.getInstitutions(commonStore.lang);
    setInstitutions(translatedInstitutions);

    let translatedThematicAreas = lookupsStore.getThematicAreas(commonStore.lang);
    setThematicAreas(translatedThematicAreas);
  }, [commonStore.lang]);

  useEffect(() => {
    let tempSessions: { text: string; value: number }[] = [];
    if (sessionCount === 0) {
      setSessions(tempSessions);
      return;
    }
    for (var i = 1; i <= sessionCount; i++) {
      tempSessions.push({ text: i.toString(), value: i });
    }
    setSessions(tempSessions);
  }, [sessionCount]);

  useEffect(() => {
    if (programId == undefined) return;

    setInProgress(true);
    agent.Programs.editProgram(programId)
      .then((result) => {
        if (result.numberOfsessions) setSessionCount(result.numberOfsessions);
        setProg(result);
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [programId]);

  const handleError = (error: any) => {
    toast.error(t("Common.GeneralErrorMessage"));
  };

  const content = (
    <div>
      <Header as="h2">{t("ProgramManagement.NewProgram")}</Header>
      <div className="injaz-forms-cancel-btn-container">
        {!isSaving && (
          <Button
            className="injaz-forms-cancel-btn"
            basic
            color="blue"
            content={t("Common.Cancel")}
            as={NavLink}
            to={"/manageprograms"}
          />
        )}
      </div>
      <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
        <Segment color="yellow" className="injaz-forms-segment-nopad">
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={prog}
            onSubmit={(values) => submitForm(values)}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-btn-container ui menu injaz-forms-btns-incl">
                  <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                </div>
                <Grid className="injaz-form-pad">
                  <Header as="h3">{t("ProgramManagement.ProgramInformation")}</Header>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group widths="equal">
                        <CustomTextInput
                          label={t("ProgramManagement.ProgramName")}
                          placeholder={t("ProgramManagement.ProgramName")}
                          name="name"
                        />
                        <CustomTextInput
                          label={t("ProgramManagement.ShortName")}
                          placeholder={t("ProgramManagement.ShortName")}
                          name="shortName"
                          valuechanged={(event: any) => {
                            setFieldValue("shortName", event.target.value);
                            setFieldValue(
                              "programConcatenatedName",
                              values.donorName +
                                "_" +
                                event.target.value +
                                "_" +
                                values.academicYearName +
                                "_" +
                                values.semsterName
                            );
                          }}
                        />
                        <CustomSelectInput
                          label={t("ProgramManagement.ThematicArea")}
                          options={thematicAreas}
                          name="thematicAreaType"
                          placeholder={t("ProgramManagement.ThematicArea")}
                        />
                        <CustomMultiSelectInput
                          name="prerequisiteCourses"
                          label={t("ProgramManagement.PrerequisiteCourses")}
                          placeholder={t("ProgramManagement.PrerequisiteCourses")}
                          options={programs}
                        />
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="extra-row-align">
                    <Grid.Column width={8}>
                      <CustomTextAreaInput
                        label={t("ProgramManagement.Brief")}
                        name="brief"
                        placeholder={t("ProgramManagement.Brief")}
                        rows={5}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CustomTextAreaInput
                        label={t("ProgramManagement.Outcomes")}
                        name="outcomes"
                        placeholder={t("ProgramManagement.Outcomes")}
                        rows={5}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <div className="ui divider"></div>
                  <Header as="h3">{t("ProgramManagement.ProgramDetails")}</Header>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group widths="equal">
                        <CustomSelectInput
                          label={t("ProgramManagement.DonorName")}
                          options={donors}
                          name="donorId"
                          placeholder={t("ProgramManagement.DonorName")}
                          onChange={(value, text) => {
                            setFieldValue("donorName", text);
                            setFieldValue(
                              "programConcatenatedName",
                              text +
                                "_" +
                                values.shortName +
                                "_" +
                                values.academicYearName +
                                "_" +
                                values.semsterName
                            );
                          }}
                        />
                        <CustomSelectInput
                          label={t("ProgramManagement.ProjectName")}
                          options={projects}
                          name="projectId"
                          placeholder={t("ProgramManagement.ProjectName")}
                        />

                        <CustomSelectInput
                          label={t("ProgramManagement.AcademicYear")}
                          options={academicYears}
                          name="academicYearId"
                          placeholder={t("ProgramManagement.AcademicYear")}
                          onChange={(value, text) => {
                            setFieldValue("academicYearName", text);
                            setFieldValue(
                              "programConcatenatedName",
                              values.donorName +
                                "_" +
                                values.shortName +
                                "_" +
                                text +
                                "_" +
                                values.semsterName
                            );
                          }}
                        />
                        <CustomSelectInput
                          label={t("ProgramManagement.Semester")}
                          options={semsters}
                          name="semester"
                          placeholder={t("ProgramManagement.Semester")}
                          onChange={(value, text) => {
                            setFieldValue("semsterName", text);
                            setFieldValue(
                              "programConcatenatedName",
                              values.donorName +
                                "_" +
                                values.shortName +
                                "_" +
                                values.academicYearName +
                                "_" +
                                text
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <CustomMultiSelectInput
                          name="targetInstitutionTypes"
                          label={t("ProgramManagement.InstitutionTypes")}
                          placeholder={t("ProgramManagement.InstitutionTypes")}
                          options={institutions}
                        />
                        <CustomTextInput
                          label={t("ProgramManagement.NumberofSessions")}
                          name="numberOfsessions"
                          disabled={programId != undefined && programId != null && +programId > 0}
                          placeholder={t("ProgramManagement.NumberofSessions")}
                          valuechanged={(event: any) => setSessionCount(event.target.value)}
                        />
                        <CustomTextInput
                          label={t("ProgramManagement.Numberofprogramhours")}
                          name="numberOfProgramHours"
                          placeholder={t("ProgramManagement.Numberofprogramhours")}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <CustomSelectInput
                          label={t("ProgramManagement.TargetGroup")}
                          options={targetGroups}
                          name="targetGroupId"
                          placeholder={t("ProgramManagement.TargetGroup")}
                        />
                        <CustomTextInput
                          label={t("ProgramManagement.Version")}
                          placeholder={t("ProgramManagement.Version")}
                          name="version"
                        />
                        <CustomTextInput
                          label={t("ProgramManagement.ProgramConcatenatedName")}
                          name="programConcatenatedName"
                          placeholder={t("ProgramManagement.ProgramConcatenatedName")}
                        />
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <div className="ui divider"></div>
                  <Header as="h3">{t("ProgramManagement.GraduationCriteria")}</Header>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <CustomTextInput
                        label={t("ProgramManagement.MinimumNumberOfAttendedSessions")}
                        placeholder={t("ProgramManagement.MinimumNumberOfAttendedSessions")}
                        name="graduationCriteria_MinimumNumberOfAttendedSessions"
                      />
                      <CustomCheckboxInput
                        name="graduationCriteria_SurveyFilled"
                        label={t("ProgramManagement.FillSurvey")}
                        type="checkbox"
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CustomMultiSelectInput
                        name="graduationCriteria_AttendSpecificSessions"
                        label={t("ProgramManagement.AttendSpecificSessions")}
                        placeholder={t("ProgramManagement.AttendSpecificSessions")}
                        options={sessions}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <div className="ui divider"></div>
                  <Header as="h3">{t("ProgramManagement.VolunteerSelectionCriteria")}</Header>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <CustomSelectInput
                        label={t("ProgramManagement.MinimumLevelOfEducation")}
                        options={educationLevels}
                        name="volunteerSelectionCriteria_MinimumLevelOfeducationId"
                        placeholder={t("ProgramManagement.MinimumLevelOfEducation")}
                      />
                      <CustomMultiSelectInput
                        label={t("ProgramManagement.Major")}
                        options={majors}
                        name="volunteerSelectionCriteria_Majors"
                        placeholder={t("ProgramManagement.Major")}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CustomTextInput
                        label={t("ProgramManagement.MinimumYearsOfExperience")}
                        placeholder={t("ProgramManagement.MinimumYearsOfExperience")}
                        name="volunteerSelectionCriteria_MinimumYearsOfExperience"
                      />
                      <CustomMultiSelectInput
                        label={t("ProgramManagement.ExperienceDomains")}
                        options={expereienceDomains}
                        name="volunteerSelectionCriteria_ExperienceDomains"
                        placeholder={t("ProgramManagement.ExperienceDomains")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        </Segment>
      </Segment.Group>
    </div>
  );

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
