import { makeAutoObservable } from "mobx";
import { InteractionRequiredAuthError, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Account } from "../models/account";
import AdminDashboard from "../Components/Dashboards/AdminDashboard";

export class UserStore {
    user: Account | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUser = (account: Account) => {
        this.user = account;
    }

    get isLoggedIn(): boolean {
        return !!this.user;
    }

    get isAdmin():boolean{
        return this.user != null && this.user.userRole.toLowerCase() === "admin";
    }

    get isTeacher():boolean{
        return this.user != null && this.user.userRole.toLowerCase() === "teacher";
    }

    get isStudent():boolean{
        return this.user != null && this.user.userRole.toLowerCase() === "student";
    }

    get isVolunteer():boolean{
        return this.user != null && this.user.userRole.toLowerCase() === "volunteer";
    }

    get isEmployee():boolean{
        return this.user != null && this.user.userRole.toLowerCase() === "employee";
    }

    login = async () => {
        const { instance } = useMsal();
        instance.loginRedirect();
    }

    logout = () => {
        const { instance } = useMsal();
        instance.logoutRedirect();
    }

    getAccessToken = async () => {
        const { instance, inProgress, accounts } = useMsal();
        const accessTokenRequest = {
            scopes: ["api://cdfc7308-2870-4fc7-b095-b166d0b6c615/access_as_user"],
            account: accounts[0],
        };
        try {
            let accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            let accessToken = accessTokenResponse.accessToken;
            return accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                instance
                    .acquireTokenPopup(accessTokenRequest)
                    .then(function (accessTokenResponse) {
                        // Acquire token interactive success
                        let accessToken = accessTokenResponse.accessToken;
                        return accessToken;
                    })
                    .catch(function (error) {
                        // Acquire token interactive failure
                        console.log(error);
                        return "";
                    });
            }
        }

    }
}