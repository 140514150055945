import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Icon, Loader, Segment, Table } from "semantic-ui-react";
import agent from "../../api/agent";
import StudentCourseCertificateDto from "../../models/Courses/studentCourseCertificateDto";
import { toast } from "react-toastify";
import { useStore } from "../../stores/store";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";

export interface Props {
  courseId: string;
  course: CourseSummaryDto | undefined;
  coursePublished: () => void;
  timeStamp: Date;
}

export default function CourseCertificates({ courseId, course, coursePublished, timeStamp }: Props) {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [records, setRecords] = useState<StudentCourseCertificateDto[]>([]);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    setInProgress(true);
    agent.Courses.studentCertificatesStatuses(+courseId)
      .then((result) => {
        setRecords(result);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setInProgress(false);
      });
  }, [courseId, timeStamp]);

  const publishCourse = () => {
    setIsPublishing(true);
    agent.Courses.publish(+courseId)
      .then((result) => {
        toast.success(t("CourseManagement.CourseHasBeenPublished"));
        coursePublished();
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setIsPublishing(false);
      });
  };

  const unpublishCourse = () => {
    setIsPublishing(true);
    agent.Courses.unpublish(+courseId)
      .then((result) => {
        toast.success(t("CourseManagement.CourseHasBeenUnPublished"));
        coursePublished();
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setIsPublishing(false);
      });
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const downloadStudentCertificate = (studentId: number, courseId: number) => {
    window.open(`/downloadstudentcertificate/${studentId}/${courseId}`, "_blank", "noopener,noreferrer");
    return;
    /*const pdf = new jsPDF();
    let element: HTMLElement = document.createElement("div");
    element.innerHTML = ReactDOM.renderToString(<Header>Certificate</Header>);
    pdf.html(element, {
      async callback(doc) {
        doc.save("pdf_name");
      },
    });*/

    /*const staticElement: HTMLElement | null = document.getElementById("pdf");
    if (staticElement == null) return;
    const data = await html2canvas(staticElement);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("shipping_label.pdf");*/
  };

  const downloadAllCertificates = () => {
    if (records == null || records.length === 0) return;
    window.open(`/downloadcoursecertificate/${courseId}`, "_blank", "noopener,noreferrer");
    return;
  };

  const content = (
    <>
      {userStore.isAdmin && (
        <Button
          className="attendance_btn"
          basic
          color="blue"
          content={course?.isPublished ? t("CourseManagement.UnPublish") : t("CourseManagement.Publish")}
          loading={isPublishing}
          onClick={() => {
            if (course?.isPublished) unpublishCourse();
            else publishCourse();
          }}
        />
      )}
      {userStore.isAdmin && !isPublishing && (
        <Button
          className="attendance_btn"
          basic
          color="blue"
          content={t("CourseManagement.DownloadAll")}
          disabled={!course?.isPublished}
          onClick={() => {
            downloadAllCertificates();
          }}
        />
      )}
      <div className="course-pub-date">
        {course?.isPublished && course?.publishDate != null && (
          <>
            {t("CourseManagement.CourseGradesPublishedOn")}: {course.publishDate}
          </>
        )}
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("UserManagement.FirstName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.LastName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.SpecificSessionsAttended")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.MinimumNumberOfAttendedSessions")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.CompletedSurveys")}</Table.HeaderCell>
            {userStore.isAdmin && <Table.HeaderCell>{t("CourseManagement.Download")}</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {records &&
            records.length > 0 &&
            records.map((record, index) => (
              <Table.Row key={index}>
                <Table.Cell>{record.studentFirstName}</Table.Cell>
                <Table.Cell>{record.studentLastName}</Table.Cell>
                <Table.Cell>{record.studentEmail}</Table.Cell>
                <Table.Cell>
                  {record.attendedRequiredSpecificSessions === true && (
                    <Icon color="olive" name="check"></Icon>
                  )}
                  {record.attendedRequiredSpecificSessions === false && (
                    <Icon color="red" name="close"></Icon>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {record.attendedMinimumNumberOfSessions === true && (
                    <Icon color="olive" name="check"></Icon>
                  )}
                  {record.attendedMinimumNumberOfSessions === false && (
                    <Icon color="red" name="close"></Icon>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {record.filledSurvey === true && <Icon color="olive" name="check"></Icon>}
                  {record.filledSurvey === false && <Icon color="red" name="close"></Icon>}
                </Table.Cell>
                <Table.Cell>
                  {userStore.isAdmin &&
                    course?.isPublished &&
                    record.filledSurvey &&
                    record.attendedMinimumNumberOfSessions &&
                    record.attendedRequiredSpecificSessions && (
                      <Icon
                        link={true}
                        color="blue"
                        name="download"
                        onClick={() => {
                          downloadStudentCertificate(record.studentId, record.courseId);
                        }}
                      />
                    )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
