import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Form, Header, Segment, Button, Grid, Dimmer, Loader } from "semantic-ui-react";
import { AcademicYearDto } from "../../models/academicYearDto";
import AddCourseDto from "../../models/Courses/addCourseDto";
import { LookupDto } from "../../models/lookupDto";
import * as Yup from "yup";
import agent from "../../api/agent";
import { Formik } from "formik";
import CustomSelectInput from "../../common/forms/CustomSelectInput";
import CustomDateInput from "../../common/forms/CustomDateInput";
import CustomMultiSelectInput from "../../common/forms/CustomMultiSelectInput";
import { useStore } from "../../stores/store";
import CustomCheckboxInput from "../../common/forms/CustomCheckboxInput";
import { programDetailsDto } from "../../models/programDetailsDto";
import { toast } from "react-toastify";

interface Params {
  courseId: string | undefined;
}

export default function AddEditCourse() {
  const { courseId } = useParams<Params>();
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [loadingPrograms, setLoadingPrograms] = useState<boolean>(false);
  const [academicYears, setAcademicYears] = useState<AcademicYearDto[]>([]);
  const [programs, setPrograms] = useState<LookupDto[]>([]);
  const [selectedProgram, setSetSelectedProgram] = useState<programDetailsDto>();
  const [institutions, setInstitutions] = useState<{ text: string; value: number }[]>([]);
  const [teachers, setTeachers] = useState<{ text: string; value: number }[]>([]);
  const [volunteers, setVolunteers] = useState<{ text: string; value: number }[]>([]);
  const [weekdays, setWeekdays] = useState<{ text: string; value: number }[]>([]);
  const [times, setTimes] = useState<{ text: string; value: number }[]>([]);
  const [classesValues, setClassesValues] = useState<{ text: string; value: number }[]>([]);
  const [sectionsValues, setSectionsValues] = useState<{ text: string; value: number }[]>([]);
  const [course, setCourse] = useState<AddCourseDto>({
    courseId: 0,
    academicYearId: undefined,
    endDate: undefined,
    startDate: undefined,
    inPerson: false,
    institutionId: 0,
    teacherId: null,
    implementationDays: undefined,
    courseTime: 8,
    programId: undefined,
    classId: null,
    sectionId: null,
    className: null,
    sectionName: null,
    volunteerId: undefined,
  });

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    if (courseId == undefined || courseId == null) return;

    setInProgress(true);
    agent.Courses.editCourse(courseId)
      .then((result) => {
        setCourse(result);
        if (result && result.academicYearId) loadAcademicYearPrograms(result.academicYearId);
        if (result && result.programId) loadProgram(result.programId);
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [courseId]);

  useEffect(() => {
    let weekdaysList = lookupsStore.getWeekdays(commonStore.lang);
    setWeekdays(weekdaysList);

    let timesList = lookupsStore.getCourseTimes(commonStore.lang);
    setTimes(timesList);

    let classesList = lookupsStore.getClassList(commonStore.lang);
    setClassesValues(classesList);

    let sectionsList = lookupsStore.getSectionList(commonStore.lang);
    setSectionsValues(sectionsList);
  }, [commonStore.lang]);

  useEffect(() => {
    agent.AcademicYears.list()
      .then((result) => setAcademicYears(result))
      .catch((error) => handleError(error));

    agent.Lookups.teachers()
      .then((result) => setTeachers(result))
      .catch((error) => handleError(error));

    agent.Lookups.volunteers()
      .then((result) => setVolunteers(result))
      .catch((error) => handleError(error));
  }, []);

  const loadProgram = (programId: number) => {
    agent.Programs.details(programId.toString())
      .then((result) => {
        setSetSelectedProgram(result);
        loadInstitutionTypes(result.institutionTypes);
      })
      .catch((error) => handleError(error));
  };

  const loadAcademicYearPrograms = (academicYearId: number) => {
    setLoadingPrograms(true);
    agent.Programs.academicYear(academicYearId)
      .then((result) => {
        setPrograms(result);
      })
      .catch((error) => handleError(error))
      .finally(() => setLoadingPrograms(false));
  };

  const loadInstitutionTypes = (types: number[] | null) => {
    if (types == null) {
      setInstitutions([]);
      return;
    }
    agent.Institutions.listLookupsByTypes(types)
      .then((result) => setInstitutions(result))
      .catch((error) => handleError(error));
  };

  const submitForm = (values: AddCourseDto) => {
    setIsSaving(true);

    if (values.classId != null)
      values.className = lookupsStore.getClassValue(values.classId, commonStore.lang);

    if (values.sectionId != null)
      values.sectionName = lookupsStore.getSectionValue(values.sectionId, commonStore.lang);

    setCourse(values);
    if (!courseId || courseId == null) addNewCourse(values);
    else updateCourse(values);
  };

  const addNewCourse = (values: AddCourseDto) => {
    agent.Courses.add(values)
      .then((result) => {
        toast.success(t("CourseManagement.CourseSavedSuccessfully"));
        history.push(`/coursedetails/${result}?setup=1`);
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 422) {
          const errorMessage = () => (
            <div>
              {t("CourseManagement.NotRegisteredOnAdoptError")}{" "}
              <a
                href="https://lms.injaz.edu.jo/auth/login/azuread-oauth2/?auth_entry=login"
                target="_blank"
              >
                here
              </a>
            </div>
          );
          toast.error(errorMessage);
        } else handleError(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateCourse = (values: AddCourseDto) => {
    agent.Courses.update(values)
      .then((result) => {
        toast.success(t("CourseManagement.CourseSavedSuccessfully"));
        history.push(`/coursedetails/${courseId}?setup=1`);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setIsSaving(false);
      });
  };

  const validationSchema = Yup.object({
    academicYearId: Yup.string().required(t("Common.Required")),
    programId: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    startDate: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    endDate: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    implementationDays: Yup.array().min(1, "Please select course days").required(t("Common.Required")),
    volunteerId: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
  });

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <div>
      <Header as="h2">{t("CourseManagement.NewCourse")}</Header>
      <div className="injaz-forms-cancel-btn-container">
        {!isSaving && (
          <Button
            className="injaz-forms-cancel-btn"
            basic
            color="blue"
            content={t("Common.Cancel")}
            as={NavLink}
            to={"/managecourses/"}
          />
        )}
      </div>
      <Segment.Group raised className="injaz-forms-segment-pad">
        <Segment color="yellow" className="injaz-forms-segment-nopad">
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={course}
            onSubmit={(values) => submitForm(values)}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-btn-container ui menu injaz-forms-btns-incl">
                  <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                </div>
                <Grid className="injaz-form-pad">
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <CustomSelectInput
                        label={t("CourseManagement.AcademicYear")}
                        options={academicYears}
                        name="academicYearId"
                        placeholder={t("CourseManagement.AcademicYear")}
                        disabled={courseId != null && courseId != undefined && +courseId > 0}
                        onChange={(value, text) => {
                          values.programId = undefined;
                          loadAcademicYearPrograms(value);
                        }}
                      />
                      <CustomSelectInput
                        name="programId"
                        label={t("ProgramManagement.ProgramName")}
                        placeholder={t("ProgramManagement.ProgramName")}
                        options={programs}
                        disabled={
                          (courseId != null && courseId != undefined && +courseId > 0) || loadingPrograms
                        }
                        onChange={(value, text) => {
                          loadProgram(value);
                        }}
                      />
                      <CustomSelectInput
                        label={t("UserManagement.Students.InstitutionName")}
                        options={institutions}
                        name="institutionId"
                        disabled={courseId != null && courseId != undefined && +courseId > 0}
                        placeholder={t("UserManagement.Students.InstitutionName")}
                      />
                      {
                        <CustomSelectInput
                          label={t("CourseManagement.TeacherName")}
                          options={teachers}
                          name="teacherId"
                          placeholder={t("CourseManagement.TeacherName")}
                          disabled={
                            selectedProgram == null ||
                            selectedProgram.institutionTypes == null ||
                            !selectedProgram.institutionTypes.includes(1) ||
                            (courseId != null && courseId != undefined && +courseId > 0)
                          }
                        />
                      }
                      <CustomSelectInput
                        label={t("UserManagement.Students.Class")}
                        options={classesValues}
                        name="classId"
                        placeholder={t("UserManagement.Students.Class")}
                        disabled={
                          selectedProgram == null ||
                          selectedProgram.institutionTypes == null ||
                          !selectedProgram.institutionTypes.includes(1) ||
                          (courseId != null && courseId != undefined && +courseId > 0)
                        }
                      />

                      <CustomSelectInput
                        label={t("UserManagement.Students.Section")}
                        options={sectionsValues}
                        name="sectionId"
                        placeholder={t("UserManagement.Students.Section")}
                        disabled={
                          selectedProgram == null ||
                          selectedProgram.institutionTypes == null ||
                          !selectedProgram.institutionTypes.includes(1) ||
                          (courseId != null && courseId != undefined && +courseId > 0)
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <label>{t("CourseManagement.StartDate")}</label>
                      <CustomDateInput label={t("CourseManagement.StartDate")} name="startDate" />
                      <label>{t("CourseManagement.EndDate")}</label>
                      <CustomDateInput label={t("CourseManagement.EndDate")} name="endDate" />
                      <CustomSelectInput
                        label={t("CourseManagement.Volunteer")}
                        options={volunteers}
                        name="volunteerId"
                        disabled={courseId != undefined && courseId != null && +courseId > 0}
                        placeholder={t("CourseManagement.Volunteer")}
                      />
                      <CustomMultiSelectInput
                        name="implementationDays"
                        label={t("CourseManagement.ImplementationWeekdays")}
                        placeholder={t("CourseManagement.ImplementationWeekdays")}
                        options={weekdays}
                      />
                      <CustomSelectInput
                        name="courseTime"
                        label={t("CourseManagement.Time")}
                        placeholder={t("CourseManagement.Time")}
                        options={times}
                      />
                      <CustomCheckboxInput
                        name="inPerson"
                        label={t("Common.InPerson")}
                        type="checkbox"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        </Segment>
      </Segment.Group>
    </div>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
