import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosResponse } from "axios";
import { AcademicYearDto } from "../models/academicYearDto";
import { Account } from "../models/account";
import AddAdminDto from "../models/Users/addAdminDto";
import AddCourseDto from "../models/Courses/addCourseDto";
import AddEmployeeDto from "../models/Users/addEmployeeDto";
import { AddTeacherDto } from "../models/Users/addTeacherDto";
import { AddVolunteerDto } from "../models/Users/addVolunteerDto";
import AppUser from "../models/Users/appUser";
import AttendanceLogDto from "../models/attendanceLogDto";
import CourseMemberDto from "../models/Courses/courseMemberDto";
import CourseSummaryDto from "../models/Courses/courseSummaryDto";
import { DonorDto } from "../models/donorDto";
import EmployeeDetailsDto from "../models/Users/employeeDetailsDto";
import { LookupDto } from "../models/lookupDto";
import MyCourseDto from "../models/Courses/myCourseDto";
import { programDetailsDto } from "../models/programDetailsDto";
import { ProgramDto } from "../models/programDto";
import { ProgramSummaryDto } from "../models/programSummaryDto"
import { ProjectDto } from "../models/projectDto";
import { CourseProgramInformationDto } from "../models/Reports/courseProgramInformationDto";
import { InstitutionInformationDto } from "../models/Reports/institutionInformationDto";
import SemesterReportDto from "../models/Reports/semesterReportDto";
import { SessionDto } from "../models/sessionDto";
import { StudentDetailsDto } from "../models/Users/Students/studentDetailsDto";
import TakeAttendanceDto from "../models/takeAttendanceDto";
import TeacherDetailsDto from "../models/Users/teacherDetailsDto";
import { UserSummary } from "../models/Users/userSummary";
import VolunteerDetailsDto from "../models/Users/volunteerDetailsDto";
import LoginService from "../services/LoginService";
import VolunteerInformationDto from "../models/Reports/volunteerInformationDto";
import CourseStudentSurveyDto from "../models/Courses/courseStudentSurveyDto";
import StudentCourseCertificateDto from "../models/Courses/studentCourseCertificateDto";
import InstitutionDto from "../models/Institutions/institutionDto";
import AddStudentDto from "../models/Users/Students/addStudentDto";
import StudentImportItem from "../models/Users/Students/studentImportItem";
import { StudentCsvValidationResult } from "../models/Users/Students/studentCsvValidationResult";

axios.defaults.baseURL = process.env.REACT_APP_API_URL + "api/";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
let msalInstance: IPublicClientApplication;
let account: AccountInfo;

const setMsalData = (instance: IPublicClientApplication, currentAccount: AccountInfo) => {
    msalInstance = instance;
    account = currentAccount;
}

//Acquire access token and append it to the authorization header before invoking the API
axios.interceptors.request.use(async (config) => {
    const loginService = new LoginService();
    const token = await loginService.getAccessToken(msalInstance, account);
    if (token && config.headers)
        config.headers.Authorization = `Bearer ${token}`;
    return config;
});

const requests = {
    get: <T>(url: string, signal?: { signal: AbortSignal }) => axios.get<T>(url, signal).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

let controller = new AbortController();
const Programs = {
    list: (filter: string | null, pageIndex: number | string | undefined, pageSize: number) => {

        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<ProgramSummaryDto[]>(`programs?filter=${searchFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`, { signal: controller.signal });
    },
    lookupList: () => requests.get<LookupDto[]>("programs/lookups"),
    academicYear: (academicYearId: number) => requests.get<LookupDto[]>(`programs/lookups/${academicYearId}`),
    count: (filter: string | null) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<number>(`programs/count?filter=${searchFilter}`)
    },
    add: (prog: ProgramDto) => requests.post('programs', prog),
    editProgram: (programId: string) => requests.get<ProgramDto>(`programs/${programId}/edit`),
    updateProgram: (prog: ProgramDto) => requests.put(`programs`, prog),
    details: (programId: string) => requests.get<programDetailsDto>(`programs/${programId}/details`),
    latest: () => requests.get<ProgramSummaryDto[]>("programs/latest"),
    changeImage: (programId: number, image: Blob) => {
        let formData = new FormData();
        formData.append('image', image);
        return axios.post(`programs/${programId}/updateImage`, formData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
    }
}

const Courses = {
    list: (filter: string | null, pageIndex: number | string | undefined, pageSize: number) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<CourseSummaryDto[]>(`courses?filter=${searchFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`, { signal: controller.signal });
    },
    count: (filter: string | null) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<number>(`courses/count?filter=${searchFilter}`)
    },
    add: (course: AddCourseDto) => requests.post<number>(`courses`, course),
    update: (course: AddCourseDto) => requests.put(`courses`, course),
    editCourse: (courseId: string) => requests.get<AddCourseDto>(`courses/${courseId}/edit`),
    details: (courseId: string) => requests.get<CourseSummaryDto>(`courses/${courseId}`),
    sessions: (courseId: string) => requests.get<SessionDto[]>(`courses/${courseId}/sessions`),
    students: (courseId: string) => requests.get<CourseMemberDto[]>(`courses/${courseId}/students`),
    addStudents: (courseId: string, users: UserSummary[]) => requests.post(`courses/${courseId}/students`, users),
    teachers: (courseId: string) => requests.get<CourseMemberDto[]>(`courses/${courseId}/teachers`),
    addTeachers: (courseId: string, users: UserSummary[]) => requests.post(`courses/${courseId}/teachers`, users),
    volunteers: (courseId: string) => requests.get<CourseMemberDto[]>(`courses/${courseId}/volunteers`),
    addVolunteers: (courseId: string, users: UserSummary[]) => requests.post(`courses/${courseId}/volunteers`, users),
    removeStudent: (courseId: string, userId: number) => requests.put(`courses/removestudent`, { UserId: userId, CourseId: courseId }),
    removeTeacher: (courseId: string, userId: number) => requests.put(`courses/removeteacher`, { UserId: userId, CourseId: courseId }),
    removeVolunteer: (courseId: string, userId: number) => requests.put(`courses/removevolunteer`, { UserId: userId, CourseId: courseId }),
    studentCourses: (studentId: number) => requests.get<CourseSummaryDto[]>(`courses/student/${studentId}`),
    studentCompletedCourses: (studentId: number) => requests.get<CourseSummaryDto[]>(`courses/student/${studentId}/completed`),
    teacherCourses: (teacherId: number) => requests.get<CourseSummaryDto[]>(`courses/teacher/${teacherId}`),
    teacherCompletedCourses: (teacherId: number) => requests.get<CourseSummaryDto[]>(`courses/teacher/${teacherId}/completed`),
    volunteerCourses: (volunteerId: number) => requests.get<CourseSummaryDto[]>(`courses/volunteer/${volunteerId}`),
    volunteerCompletedCourses: (volunteerId: number) => requests.get<CourseSummaryDto[]>(`courses/volunteer/${volunteerId}/completed`),
    myCourses: () => requests.get<MyCourseDto[]>(`courses/mycourses`),
    myCompletedCourses: () => requests.get<MyCourseDto[]>(`courses/mycompletedcourses`),
    getStudentSurveys: (courseId: number) => requests.get<CourseStudentSurveyDto[]>(`courses/${courseId}/surveys`),
    saveStudentSurveys: (courseId: number, surveys: CourseStudentSurveyDto[]) => requests.post<CourseStudentSurveyDto[]>(`courses/${courseId}/surveys`, surveys),
    studentCertificatesStatuses: (courseId: number) => requests.get<StudentCourseCertificateDto[]>(`courses/${courseId}/certificatesstatuses`),
    studentCerticateInfo: (studentId: number, courseId: number) => requests.get<StudentCourseCertificateDto>(`courses/${courseId}/certificate/${studentId}`),
    certicates: (courseId: number) => requests.get<StudentCourseCertificateDto[]>(`courses/${courseId}/certificates`),
    publish: (courseId: number) => requests.post(`courses/${courseId}/publish`, courseId),
    unpublish: (courseId: number) => requests.post(`courses/${courseId}/unpublish`, courseId)
}

const Sessions = {
    add: (session: SessionDto) => requests.post(`sessions`, session),
    update: (session: SessionDto) => requests.put(`sessions`, session),
    viewAttendance: (sessionId: number) => requests.get<AttendanceLogDto[]>(`sessions/${sessionId}/attendance`),
    initializeAttendance: (sessionId: number) => requests.get<TakeAttendanceDto[]>(`sessions/${sessionId}/initializeAttendance`),
    saveAttendance: (sessionId: number, logs: TakeAttendanceDto[]) => requests.post(`sessions/${sessionId}/takeattendance`, logs),
    deleteSession: (sessionId: number) => requests.delete(`sessions/${sessionId}`)
}

const Accounts = {
    current: () => requests.get<Account>("account"),
};

const Users = {
    list: (userType: string, filter: string | null, pageIndex: number | string | undefined, pageSize: number) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<UserSummary[]>(`users/${userType}?filter=${searchFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
    },
    count: (userType: string, filter: string | null) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<number>(`users/${userType}/count?filter=${searchFilter}`)
    },
    details: (userId: string) => requests.get<AppUser>(`users/${userId}/profile`),
    addTeacher: (teacher: AddTeacherDto) => requests.post("users/teachers", teacher),
    editTeacher: (userId: string) => requests.get<AddTeacherDto>(`users/teachers/${userId}/edit`),
    updateTeacher: (teacher: AddTeacherDto) => requests.put(`users/teachers`, teacher),
    addStudent: (student: AddStudentDto) => requests.post("users/students", student),
    editStudent: (userId: string) => requests.get<AddStudentDto>(`users/students/${userId}/edit`),
    updateStudent: (student: AddStudentDto) => requests.put(`users/students`, student),
    validateStudentsCsv: (students: StudentImportItem[]) => requests.post<StudentCsvValidationResult[]>(`users/students/validatecsv`, students),
    importStudents: (students: StudentImportItem[], schoolId: string) => requests.post(`users/students/${schoolId}/import`, students),
    addVolunteer: (volunteer: AddVolunteerDto) => requests.post("users/volunteers", volunteer),
    editVolunteer: (userId: string) => requests.get<AddVolunteerDto>(`users/volunteers/${userId}/edit`),
    updateVolunteer: (volunteer: AddVolunteerDto) => requests.put(`users/volunteers`, volunteer),
    addAdmin: (admin: AddAdminDto) => requests.post("users/administrators", admin),
    addEmployee: (employee: AddEmployeeDto) => requests.post("users/employees", employee),
    editEmployee: (userId: string) => requests.get<AddEmployeeDto>(`users/employees/${userId}/edit`),
    updateEmployee: (employee: AddEmployeeDto) => requests.put(`users/employees`, employee),
    employeeSubordinates: (userId: string) => requests.get<UserSummary[]>(`users/${userId}/subordinates`),
    editAdmin: (userId: string) => requests.get<AddAdminDto>(`users/administrators/${userId}/edit`),
    updateAdmin: (admin: AddAdminDto) => requests.put(`users/administrators`, admin)
};

const Profiles = {
    teacher: (teahcerId: string) => requests.get<TeacherDetailsDto>(`profiles/teacher/${teahcerId}`),
    student: (studentId: string) => requests.get<StudentDetailsDto>(`profiles/student/${studentId}`),
    volunteer: (volunteerId: string) => requests.get<VolunteerDetailsDto>(`profiles/volunteer/${volunteerId}`),
    employee: (employeeId: string) => requests.get<EmployeeDetailsDto>(`profiles/employee/${employeeId}`)
};

const Donors = {
    list: () => requests.get<DonorDto[]>("donors"),
};

const Projects = {
    list: () => requests.get<ProjectDto[]>("projects"),
};

const AcademicYears = {
    list: () => requests.get<AcademicYearDto[]>("academicyears"),
};

const ExperienceDomains = {
    list: () => requests.get<LookupDto[]>("lookups/ExperienceDomains"),
};

const EducationLevels = {
    list: () => requests.get<LookupDto[]>("lookups/EducationLevels"),
};

const Institutions = {
    list: (filter: string | null, pageIndex: number | string | undefined, pageSize: number) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<InstitutionDto[]>(`institutions?filter=${searchFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
    },
    count: (filter: string | null) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<number>(`institutions/count?filter=${searchFilter}`)
    },
    details: (institutionId: string) => requests.get<InstitutionDto>(`institutions/${institutionId}`),
    listLookups: () => requests.get<LookupDto[]>("institutions/lookups"),
    listLookupsByTypes: (types: number[]) => requests.post<LookupDto[]>(`institutions/bytypes/lookups`, types),
    add: (institution: InstitutionDto) => requests.post<number>(`institutions`, institution),
    update: (institution: InstitutionDto) => requests.put(`institutions`, institution),
    students: (institutionId: number, filter: string | null, pageIndex: number | string | undefined, pageSize: number) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<UserSummary[]>(`institutions/${institutionId}/students?filter=${searchFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
    },
    studentsCount: (institutionId: number, filter: string | null, pageIndex: number | string | undefined, pageSize: number) => {
        if (controller)
            controller.abort();
        controller = new AbortController();
        let searchFilter = (!filter || filter === "" || filter == null) ? "all" : filter;
        return requests.get<number>(`institutions/${institutionId}/studentsCount?filter=${searchFilter}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
    }
};

const Lookups = {
    governates: () => requests.get<LookupDto[]>("lookups/Governates"),
    directorates: () => requests.get<LookupDto[]>("lookups/directorates"),
    countries: () => requests.get<LookupDto[]>("lookups/Countries"),
    educationInstitutions: () => requests.get<LookupDto[]>("lookups/EducationInstitutions"),
    schools: () => requests.get<LookupDto[]>("schools"),
    majors: () => requests.get<LookupDto[]>("lookups/Majors"),
    targetGroups: () => requests.get<LookupDto[]>("lookups/TargetGroups"),
    teachers: () => requests.get<LookupDto[]>("users/teachers"),
    volunteers: () => requests.get<LookupDto[]>("users/volunteers"),
    courseVolunters: (courseId: number) => requests.get<LookupDto[]>(`Courses/${courseId}/volunteerslookups`),
    employees: () => requests.get<LookupDto[]>("users/employees")
};

const Reports = {
    courseProgramReport: () => requests.get<CourseProgramInformationDto[]>('reports/coureprograminformation'),
    institutionInformationReport: () => requests.get<InstitutionInformationDto[]>('reports/institutioninformation'),
    semesterReport: () => requests.get<SemesterReportDto[]>('reports/semesterreport'),
    volunteersReport: () => requests.get<VolunteerInformationDto[]>('reports/volunteerinformation')
}


const agent = {
    setMsalData,
    Programs,
    Accounts,
    Users,
    Donors,
    Projects,
    AcademicYears,
    Profiles,
    ExperienceDomains,
    EducationLevels,
    Institutions,
    Lookups,
    Courses,
    Sessions,
    Reports
};

export default agent;