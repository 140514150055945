import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Dimmer, Loader, Message, Pagination, Segment, Table } from "semantic-ui-react";
import InstitutionDto from "../../models/Institutions/institutionDto";
import { useStore } from "../../stores/store";

export interface Props {
  institutions: InstitutionDto[] | undefined;
  loadingInProgress: boolean;
  currentPage: number | string | undefined;
  pageSize: number;
  totalItemsCount: number;
  blockActions: boolean;
  onPageChanged: (index: number | string | undefined) => void;
}

export default function InstitutionsList({
  institutions,
  loadingInProgress,
  blockActions,
  onPageChanged,
  totalItemsCount,
  currentPage,
  pageSize,
}: Props) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const emptyList = (
    <Segment className="injaz-empty-list-msg">
      <Message className="empty-list-msg" header={t("Institutions.NoInstitutions")} />
    </Segment>
  );

  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalItemsCount / pageSize)}
      onPageChange={(e, d) => onPageChanged(d.activePage)}
    />
  );

  const list = (
    <Segment className="injaz-manage-segment">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.Name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.InstitutionType")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.Governate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.Directorate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.Gender")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.DoubleShift")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Institutions.HasSyrianStudentsPeriod")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {institutions &&
            institutions.map((institution) => (
              <Table.Row key={institution.id}>
                <Table.Cell>{institution.id}</Table.Cell>
                <Table.Cell>
                  <NavLink to={`/institutions/${institution.id}/details`}>{institution.name}</NavLink>
                </Table.Cell>
                <Table.Cell>
                  {lookupsStore.getInstitutionsTypesValue(institution.institutionType, commonStore.lang)}
                </Table.Cell>
                <Table.Cell>{institution.governateName}</Table.Cell>
                <Table.Cell>{institution.directorateName}</Table.Cell>
                <Table.Cell>
                  {institution.genderType && lookupsStore.getGenderPreferenceValue(institution.genderType, commonStore.lang)}
                </Table.Cell>
                <Table.Cell>
                  {institution.schoolWorksWithDoubleShift ? t("Common.Yes") : t("Common.Yes")}
                </Table.Cell>
                <Table.Cell>
                  {institution.hasSyrianStudentsPeriod ? t("Common.Yes") : t("Common.Yes")}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalItemsCount > 10 && gridPaging}
    </Segment>
  );

  return (
    <>
      {loadingInProgress && loader}
      {!loadingInProgress && institutions != null && institutions.length > 0 && list}
      {!loadingInProgress && (institutions == null || institutions.length === 0) && emptyList}
    </>
  );
}
